<template>
  <div class="home">
    <el-button @click="$router.push({path:'/SaoYiSao'})">打开扫一扫</el-button>
    {{ mydata }}
  </div>
</template>

<script>
export default {
  name: 'HomeView',

  data() {
    return {
      mydata: null,
 
    }
  },
  created() {

  },
  methods: {
    open() {
    },
  },
}
</script>

<style scoped>
 .saoma {
        width: 100vw;
        height: 100vh;
    }
</style>
